:root {
  --primary: #2ec625;
  --green-bg: #77dc56;
  --background-gray: #f5f6f8;
  --white: #fff;
  --gray: #757575;
  --menu-bg: #323c3c;
  --thin-gray: #e6e6e6;
  --medium-gray: #c6c6c6;
  --danger: #ff444e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-gray);
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.custom-outlined-input:disabled {
  background: #ccc !important;
}

/*  Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* border: 1px solid var(--thin-gray); */
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--medium-gray);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}

.MuiTabScrollButton-root.Mui-disabled {
  display: none;
}
